import { InputProps, OutlinedTextFieldProps, StandardTextFieldProps } from '@material-ui/core'
import { CountryCode as PhoneCountryCode, formatIncompletePhoneNumber, parseDigits } from 'libphonenumber-js'
import React from 'react'
import { useSelector } from 'react-redux'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors/UserSelectors'
import { Country } from '@lazr/openapi-client'
import { Label, TextField as CustomTextField } from './TextField.styled'

const PhoneNumberField: React.FunctionComponent<Props> = ({ value, onChange, label, disabled = false, required, countryCode,  ...otherProps }) => {
    const user = useSelector(reduxGetUser)
    const userCountry = (countryCode ? countryCode : user?.locale.country ? user.locale.country : Country.CA) as PhoneCountryCode;

    return <div>
        {label && <Label $disabled={!!disabled}>
            {required && label ? `${label} *` : `${label ?? ''}`}
        </Label>}
        <CustomTextField
            {...otherProps}
            value={formatIncompletePhoneNumber(value, userCountry)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                let newValue = parseDigits(event.target.value)
                if (newValue === value && userCountry && formatIncompletePhoneNumber(newValue, userCountry).startsWith(event.target.value)) {
                    newValue = newValue.slice(0, -1)
                }
                onChange(newValue)
            }}
            disabled={disabled}
        />
    </div>
}
export interface Props extends Omit<OutlinedTextFieldProps | StandardTextFieldProps, 'select' | 'onChange'> {
    id?: string
    disabled?: boolean
    className?: string
    value: string
    label?: string
    fullWidth?: boolean
    required?: boolean
    error?: boolean
    helperText?: React.ReactNode
    autoComplete?: string
    placeholder?: string
    size?: 'small' | 'medium'
    inputProps?: InputProps['inputProps']
    onBlur?: InputProps['onBlur']
    onChange: (newValue: string) => void
    countryCode?: Country | null
}

export default PhoneNumberField

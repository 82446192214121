import React, {
    ReactNode,
    isValidElement,
    Children,
  } from 'react';
  import { renderToString } from 'react-dom/server';
  import {
    Box,
    Typography,
  } from './MoreLess.styled';
  
  export interface MoreLessProps {
    icon?: React.ReactNode;
    text: string | React.ReactNode;
    truncateAtChar: number;
  }
  
  export const MoreLess = ({ text, truncateAtChar, icon }: MoreLessProps) => {
    const [more, setMore] = React.useState(false);
  
    const regex = /<br>(?=(?:\s*<[^>]*>)*$)|(<br\s*\/?>)|<(?!\/?StyledCommodityTypeIcon\b)[^>]+>/gi;
  
    if (!text) return null;
  
    let truncatedText: string;
  
    if (typeof text === 'string') {
      truncatedText = text;
    } else {
      const childrenArray = Children.toArray(text as ReactNode);
  
      const rendered = childrenArray
        .map(child => {
          if (isValidElement(child)) {
            return renderToString(child);
          }
          return child?.toString?.() || '';
        })
        .join('');
  
      truncatedText = rendered.replace(regex, (x, y) => (y ? ' & ' : ''));
    }
  
    const shouldTruncate = truncatedText.length > truncateAtChar;
  
    return (
      <Box>
        <Typography>
          {icon ? <>{icon}&nbsp;&nbsp;</> : null}
          {!shouldTruncate
            ? text
            : !more
            ? `${truncatedText.slice(0, truncateAtChar)}...`
            : text}
          {shouldTruncate && (
            <span
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setMore(!more);
              }}
              style={{ cursor: 'pointer', color: '#007bff', marginLeft: '8px' }}
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') setMore(!more);
              }}
            >
              {more ? 'less' : 'more'}
            </span>
          )}
        </Typography>
      </Box>
    );
  };
  